import { Helper } from './Helper';

export class Constants {
    private static instance: Constants;
    private _Api = {
        Url: "",
        Version: "",
        Localhost: ""
    };
    private _Routes = {
        Devices: "",
        Users: "",
        Scenarios: "",
        Locations: "",
        DeviceSnapshots: "",
        Files: ""
    };
    private DeviceHealthApi = "";
    private DevicesApi = "";
    private DevicesPaginationApi = "";
    private UserListPaginationApi = "";
    private CreateUserApi = "";
    private UpdateUserApi = "";
    private EditDeviceLocationApi = "";
    private DisableUserApi = "";
    private GetUserApi = "";
    private ElevateUserApi = "";
    private GetVmDetailsApi = "";
    private DeployVmApi = "";
    private ListScenarioApi = "";
    private LocationApi = "";
    private FileUploadApi = "";
    private RedirectUri = "";
    private Authority = "";
    private AppId = "";
    private AppInsightInstrumentationKey = "";
    private correlationID = Helper.NewGuid();
    private pageSize = 20;

    public static getInstance = () => {
        if (!Constants.instance) {
            Constants.instance = new Constants();
        }
        return Constants.instance;
    };

    public constructor() {
    }


    public static getNonDCATApprovedScenarios() {
        return ["SocLenel", "ServerDell", "ServerZt", "ServerMicrosoftDct", "MixedRealityHololens", "ServerHpe", "DcRobotResearch", "Quanta", "DigitalProcedures", "ServerOracle", "ServerHpeHpc", "ServerLenovoDatabox", "ValyrianSupport", "ValyrianOps", "AdminSpaces"];
    }
    public static getNonCOIScenarios() {
        return [ "ServerHpe"];
    }

    get CorrelationID() {
        return this.correlationID;
    }

    get PageSize() {
        return this.pageSize;
    }
    get DeviceLocationMetro(): string {
        return 'Location/metro';
    }
    get DeviceLocationCampus(): string {
        return 'Location/campus';
    }
    get DeviceLocationFacility(): string {
        return 'Location/facility';
    }
    get DeviceLocationRoom(): string {
        return 'Location/room';
    }

    get DeviceLocationScenario(): string {
        return 'Location/scenario';
    }

    get DeviceLocationSerialNumber(): string {
        return 'Location/serialNumber';
    }

    get Bearer() {
        return 'Bearer ';
    }

    get DeviceActionReassignScenario(): string {
        return "action/reassignscenario";
    }

    public static previewFeaturesEnabled(user: any) {
        return user.TenantId === Constants.getInstance().xdcTenantId;
    }
    
    get DecommissionDeviceAction(): string {
        return "action/decommission";
    }
    get DeviceActionEditLocation(): string {
        return "action/editlocation";
    }

    get VmDeploymentAction(): string {
        return "action/deployvm";
    }

    get Api() {
        if (process.env.REACT_APP_ApiUrl) {
            this._Api.Url = process.env.REACT_APP_ApiUrl;
        }

        if (process.env.REACT_APP_ApiVersion) {
            this._Api.Version = process.env.REACT_APP_ApiVersion;
        }

        if (process.env.REACT_APP_ApiLocalhost) {
            this._Api.Localhost = process.env.REACT_APP_ApiLocalhost;
        }

        return {
            Url: this._Api.Url,
            Version: this._Api.Version,
            Localhost: this._Api.Localhost
        };
    }

    get Routes() {
        if (process.env.REACT_APP_DevicesApiRoute) {
            this._Routes.Devices = process.env.REACT_APP_DevicesApiRoute;
        }

        return {
            Devices: this._Routes.Devices,
            Users: "",
            Scenarios: "",
            Locations: "",
            DeviceSnapshots: "",
            Files: ""
        };
    }

    get DeviceHealthApiEndpoint() {
        if (process.env.REACT_APP_DeviceHealthApi) {
            this.DeviceHealthApi = process.env.REACT_APP_DeviceHealthApi;
        }

        return this.DeviceHealthApi;
    }

    get DevicesApiEndpoint() {

        if (process.env.REACT_APP_DevicesApi) {
            this.DevicesApi = process.env.REACT_APP_DevicesApi;
        }

        return this.DevicesApi;
    }

    get EditDeviceLocationApiEndpoint() {
        if (process.env.REACT_APP_EditDeviceLocationApi) {
            this.EditDeviceLocationApi = process.env.REACT_APP_EditDeviceLocationApi;
        }

        return this.EditDeviceLocationApi;
    }

    get DevicesPaginationApiEndpoint() {

        if (process.env.REACT_APP_DevicesPageApi) {
            this.DevicesPaginationApi = process.env.REACT_APP_DevicesPageApi;
        }

        return this.DevicesPaginationApi;
    }

    get UserListPaginationApiEndpoint() {

        if (process.env.REACT_APP_UserListPageApi) {
            this.UserListPaginationApi = process.env.REACT_APP_UserListPageApi;
        }

        return this.UserListPaginationApi;
    }

    get ListScenarioApiEndpoint() {

        if (process.env.REACT_APP_ListScenarioApi) {
            this.ListScenarioApi = process.env.REACT_APP_ListScenarioApi;
        }

        return this.ListScenarioApi;
    }

    get CreateUserApiEndpoint() {
        if (process.env.REACT_APP_CreateUserApi) {
            this.CreateUserApi = process.env.REACT_APP_CreateUserApi;
        }

        return this.CreateUserApi;
    }
    get UpdateUserApiEndpoint() {
        if (process.env.REACT_APP_UpdateUserApi) {
            this.UpdateUserApi = process.env.REACT_APP_UpdateUserApi;
        }

        return this.UpdateUserApi;
    }
    get DisableUserApiEndpoint() {
        if (process.env.REACT_APP_DisableUserApi) {
            this.DisableUserApi = process.env.REACT_APP_DisableUserApi;
        }
        return this.DisableUserApi;
    }

    get GetUserApiEndpoint() {
        if (process.env.REACT_APP_GetUserApi) {
            this.GetUserApi = process.env.REACT_APP_GetUserApi;
        }

        return this.GetUserApi;
    }

    get GetVmDetailsEndpoint(): string {
        if (process.env.REACT_APP_GetVmDetails) {
            this.GetVmDetailsApi = process.env.REACT_APP_GetVmDetails;
        }

        return this.GetVmDetailsApi;
    }

    get DeployVmEndpoint(): string {
        if (process.env.REACT_APP_DeployVmApi) {
            this.DeployVmApi = process.env.REACT_APP_DeployVmApi;
        }

        return this.DeployVmApi;
    }


    get ElevateUserGraphApiEndpoint() {
        return "https://graph.microsoft.com/v1.0/roleManagement/directory/roleAssignmentScheduleRequests/";
    }

    get UserRolesGraphAPIEndPoint() {
        return "https://graph.microsoft.com/v1.0/roleManagement/directory/roleEligibilitySchedules/filterByCurrentUser(on='principal')?$expand=roleDefinition";
    }

    get FileUploadApiEndpoint() {
        if (process.env.REACT_APP_FileUploadApi) {
            this.FileUploadApi = process.env.REACT_APP_FileUploadApi;
        }

        return this.FileUploadApi;
    }

    get AuthRedirectUri() {
        if (process.env.REACT_APP_RedirectUri) {
            this.RedirectUri = process.env.REACT_APP_RedirectUri;
        }
        return this.RedirectUri;
    }

    get LocationApiEndpoint() {

        if (process.env.REACT_APP_LocationApi) {
            this.LocationApi = process.env.REACT_APP_LocationApi;
        }
        return this.LocationApi;
    }

    get InstrumentationKey() {
        if (process.env.REACT_APP_APPINSIGHTS_KEY) {
            this.AppInsightInstrumentationKey = process.env.REACT_APP_APPINSIGHTS_KEY;
        }
        return this.AppInsightInstrumentationKey;
    }

    get AuthAuthority() {
        if (process.env.REACT_APP_Authority) {
            this.Authority = process.env.REACT_APP_Authority;
        }
        return this.Authority;
    }

    get AuthAppId() {
        if (process.env.REACT_APP_AppId) {
            this.AppId = process.env.REACT_APP_AppId;
        }
        return this.AppId;
    }

    get getSupportEmail() {
        return "valsupport@microsoft.com";
    }

    get MaxFileSize() {
        return {
            'ThreeMB': 3145728,
            'TenMB': 10485758
        };
    }

    get MaxTitleLength() {
        return 255;
    }

    get NoCache() {
        return 'no-cache';
    }

    get getFlights() {
        return {
            EnableTestFlight: "testflight"
        };
    }

    get getRegex() {
        return {
            // eslint-disable-next-line
            PhoneRegex: "^((\\()?[+]?(\d|\\-|\\(|\\)|\\.|\\s)*(\\d|\\-|\\(|\\)|\\.|\\s)+(\\d|\\-|\\(|\\)|\\.)*)$",
            EmailRegex: "^[^@]+@[^@]+\\.[^@]+$"
        };
    }

    // Constants for logging and tracing
    get TraceTypes() {
        return {
            Information: 'Information',
            Error: 'Error',
            ActionSuccess: "Action succeeds",
            ActionFail: "Action failed",
            TrackEvent: "Track event"
        };
    }
    get TraceTags() {
        return {
            HttpRequest: "HttpRequest",
            HttpResponse: "HttpResponse",
            JavascriptError: "Javascript error"
        };
    }
    get entryHeaders() {
        return {
            msCorrelationId: 'MS-CorrelationId',
            xCorrelationId: 'X-Correlation-Id'
        };
    }

    get notAvailable() {
        return 'Not Available';
    }

    get deafutLocale() {
        return 'en-us';
    }

    get sessionContextInfoTitles() {
        return {
            sessionProperties: "Session Properties",
            property: "Property",
            value: "Value",
            buildId: "Build Id",
            sessionId: "Session Id",
            tenantId: "Tenant Id",
            locale: "Locale",
            corelationId: "Correlation Id",
            claims: "Claims",
            flights: "Flights"
        };
    }

    get tracingInfoTitles() {
        return {
            tracingInfo: "Tracing info",
            id: "ID",
            location: "Location",
            type: "Type",
            tags: "Tags",
            correlationId: "CorrelationId",
            entry: "Entry"
        };
    }

    get xdcTenantId() {
        return "9dc2b588-26e1-4218-9c15-79943c228c19";
    }

    get dcTenantId() {
        return "8ae0b6fa-27c0-4e3d-be50-67b0134be50d";
    }

    get xdcDomainName() {
        return "xdc.microsoft";
    }

    get dcDomainName() {
        return "dc.microsoft";
    }

    get atSymbol() {
        return "@";
    }
}