import { IDropdownOption } from '@fluentui/react';
export class VMMetaDataModel {
    readonly id: string;
    readonly scenario: string;
    readonly prevVersion: string;
    readonly currVersion: string;
    readonly fileName: string;
    readonly sha: string;
    readonly vmStatus: string;
    readonly createdTime: string;
    readonly updatedBy: string;
    readonly updatedTime: string;
    readonly userVersion: string;
    readonly etag: string;
    readonly error: {
        code: string,
        message: string,
    };

    constructor(id: string, scenario: string, prevVersion: string, currVersion: string, fileName: string, sha: string, vmStatus: string, createdTime: string, updatedBy: string, updatedTime: string, userVersion: string, etag: string, Error: {
        code: string,
        message: string,
    }) {
        this.id = id;
        this.scenario = scenario;
        this.prevVersion = prevVersion;
        this.currVersion = currVersion;
        this.fileName = fileName;
        this.sha = sha;
        this.vmStatus = vmStatus;
        this.createdTime = createdTime;
        this.updatedBy = updatedBy;
        this.updatedTime = updatedTime;
        this.userVersion = userVersion;
        this.etag = etag;
        this.error = Error;
    }
}

export const EmptyVMMetaDataModel = new VMMetaDataModel("", "", "", "", "", "", "", "", "", "", "", "", {
    code: "",
    message: ""
});

export default EmptyVMMetaDataModel;

export class VMHelper {
    public static VMListToDropdownOptions(responses: VMMetaDataModel[]): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        responses.sort((a, b) => a.fileName < b.fileName ? -1 : 1).forEach((option) => {
            options.push({ key: option.id, text: option.fileName });
        });

        return options;
    }
}
