import React, { Component } from 'react';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { initializeIcons } from '@uifabric/icons';

import { ApplicationInsightsLogger } from 'Services/Logger';
import { CreateSingleUser } from './Components/Pages/UserManagement/CreateSingleUser';
import { UserElevation } from './Components/Pages/UserManagement/UserElevation';
import { UserManagementListPage } from './Components/Pages/UserManagement/UserManagementList';
import { DeviceHealth } from './Components/Pages/DeviceHealth/DeviceHealth';
import { DeviceInventory } from './Components/Pages/DeviceInventory/DeviceInventoryPage';
import {DeviceOnboarding} from './Components/Pages/DeviceOnboarding/DeviceOnboarding';
import { FileUpload } from './Components/Pages/FileManagement/FileUpload';
import { ScenarioManagementPage } from './Components/Pages/ScenarioManagement/ScenarioManagementList';
import { Landing } from './Components/Pages/Landing';
import { Layout } from './Components/Common/Layout/Layout';
import { Marketing } from './Components/Pages/Marketing';
import { Constants } from './Utilities/Constants';
import { UserContextProvider } from './Auth/UserContext';
import withAuthProvider, { AuthComponentProps } from './Auth/AuthProvider';

initializeIcons();

const history = createBrowserHistory();

const PrivateRoute = ({
    IsAutenticated,
    component: Component,
    ...rest
}: {
    exact: boolean;
    path: string;
    IsAutenticated: boolean;
    component: any;
}) => <Route {...rest} render={(props) => (IsAutenticated === true ? <Component {...props} /> : <Landing />)} />;

const DeviceHealthRoute = '/DeviceHealth';
const DeviceInventoryRoute = '/DeviceInventory';
const DeviceInventoryClassicRoute = '/DeviceInventoryClassic';
const DeviceOnboardingRoute = '/DeviceOnboarding';
const CreateSingleUserRoute = '/CreateSingleUser';
const UserElevationRoute = '/UserElevation';
const FileUploadRoute = '/FileUpload';
const UserManagementRoute = '/UserManagement';
const ScenarioManagementRoute = '/ScenarioManagement';

const logger = ApplicationInsightsLogger.getInstance();

class App extends Component<AuthComponentProps> {
    render() {
        // TODO: How will we display these errors?
        if (this.props.error) {
            logger.logEvent("Authentication failure", this.props.error);
        }

        return (
            <UserContextProvider value={this.props.user}>
                <Layout
                    isAuthenticated={this.props.isAuthenticated}
                    authButtonMethod={this.props.isAuthenticated ? this.props.logout : this.props.login}
                    user={this.props.user}
                >
                    <Router history={history}>
                        <div>
                            <PrivateRoute
                                exact
                                path="/"
                                IsAutenticated={this.props.isAuthenticated}
                                component={Marketing}
                            />
                            <PrivateRoute
                                exact
                                path={CreateSingleUserRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={CreateSingleUser}
                            />
                            <PrivateRoute
                                exact
                                path={UserElevationRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={UserElevation}
                            />
                            <PrivateRoute
                                exact
                                path={DeviceHealthRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={DeviceHealth}
                            />
                            <PrivateRoute
                                exact
                                path={DeviceInventoryRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={DeviceInventory}
                            />
                            <PrivateRoute
                                exact
                                path={DeviceOnboardingRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={DeviceOnboarding}
                            />
                            <PrivateRoute
                                exact
                                path={FileUploadRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={FileUpload}
                            />
                            <PrivateRoute
                                exact
                                path={UserManagementRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={UserManagementListPage}
                            />
                            <PrivateRoute
                                exact
                                path={ScenarioManagementRoute}
                                IsAutenticated={this.props.isAuthenticated}
                                component={ScenarioManagementPage}
                            />
                        </div>
                    </Router>
                </Layout>
            </UserContextProvider>
        );
    }
}
export default withAuthProvider(App);
