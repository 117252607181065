import IResourceStrings from './IResourceKeys';

// TODO: Generate keys from string source (resx or json)
export const ResourceStrings: IResourceStrings = {
    Common: {
        Microsoft: "Microsoft",
        ErrorMessage: "Failed to load data",
        Loading: "Loading",
        Search: "Search",
        GeneralErrorMessage: "Something went wrong, please contact:",
        Submit: "Submit",
        Clear: "Clear",
        Close: "Close",
        Cancel: "Cancel",
        invalidMessage: "Invalid Value provided",
        New: "New",
        Current: "Current",
        NotAvailable: "Not Available",
        Overview: "Overview"
    },
    NavBar: {
        SecureDevicesPortal: "Secure Devices Portal",
        Hello: "Hello",
        SignIn: "Hello, Sign In",
        Home: "Home",
        DeviceHealth: "Device Health",
        DeviceInventory: "Device Inventory",
        DeviceOnboarding: "Device Onboarding",
        Devices: "Devices",
        Users: "Users",
        Files: "Files",
        FileUpload: "File Upload",
        UserElevation: "Elevate User",
        CreateUser: "Create User",
        ElevateRole: "My Role Elevation",
        ManageUsers: "Manage Users",
        Scenarios: "Scenarios",
        ManageScenarios: "Manage Scenarios"
    },
    Locations: {
        Location: "Location",
        Metro: 'Metro',
        Campus: 'Campus',
        Facility: 'Facility',
        Room: 'Room',
        Locker: "Locker",
        Slot: "Slot",
        FacilityPlaceholder: 'Please enter the Facility code',
        RoomPlaceholder: 'Please enter the Room number',
        LockerPlaceholder: 'Please enter the Locker number',
        SlotPlaceholder: 'Please enter the Slot number',
        FacilityInvalidMessage: 'Invalid Input. Please enter a value between 3 and 6 characters',
        RoomInvalidMessage: 'Invalid Input. Please enter a value between 1 and 15 characters',
        LockerInvalidMessage: 'Invalid Input. Please enter a value between 1 and 15 characters',
        SlotInvalidMessage: 'Invalid Input. Please enter a value between 1 and 15 characters'
    },

    Scenarios: {
        Scenario: 'Scenario',
        Description: 'Description',
        ScenarioOwner: 'Scenario Owner',
        ScenarioInvalidMessage: 'Invalid Input. Please enter a value between 1 and 15 characters',
        Variant: 'Variant',
        ScenarioList: 'Scenario List'
    },
    ScenarioAction: {
        VMDeployment: {
            Title: "Deploy VM",
            ActionName: "VM Deployment",
            Description: "Deploy the VM to the Canary group first. Do not promote to Production until the Canary devices are verified.",
            Message: {
                Success: "Successfully deployed {0} for scenario {1}."
            }
        }
    },
    Footer: {
        Feedback: "Feedback",
        About: "About Us",
        ContactUs: "Contact Us",
        MicrosoftGlobalDataPrivacyNotice: "Microsoft Global Data Privacy Notice",
        MicrosoftPrivacyStatement: "Microsoft Privacy Statement"
    },
    Landing: {
        PleaseSignIn: "Please sign in with your Valyrian account to view the portal. Kindly use your site adminstrator Valyrian login for administrator view"
    },
    Device: {
        Name: "Device Name",
        SerialNumber: "Serial Number",
        AssetId: "Asset Id",
        AutoPilotRegistrationDate: "AP Registration Date",
        Scenario: "Device Type",
        Unauthorized: "Please login as a Site Administrator",
        NoDevicesFound: "No devices were found",
        DeviceState: "Device State",
        SerialNumberPlaceholder: 'Please enter the Serial number of device',
        AssetIdPlaceholder: 'Please enter the Asset Id',
        ScenarioIdsPlaceholder: 'Please select the Device type',
        SerialNumberInvalidMessage: 'Invalid Input. Please enter a value between 6 and 15 characters',
        AssetIdInvalidMessage: 'Invalid Input. Please enter a value between 6 and 15 characters',
        ScenarioIdsInvalidMessage: 'Type is Invalid',
        Inventory: 'Device List',
        Decommission: 'Please click on confirm button to decommission this device'
    },
    DeviceAction: {
        ReassignScenario: {
            Title: "Reassign Device Type",
            ActionName: "Reassign",
            Description: "Reassigning a device to a new scenario will remotely trigger a full device wipe. This process can take 4 to 8 hours, depending on internet connection. The device will be in Servicing state until the device completes its wipe and re-enrollment.",
            Message: {
                Success: "Reassigning this device type. This process can take 4 to 8 hours if the device is powered on and connected to internet. For support status, refer to action Id: "
            },
            FormError: {
                FieldUnchanged: "{0} must be different from current."
            }
        },
        DecommissionDevice: {
            Title: " Decommission Device",
            ActionName: "Decommission",
            Message: {
                Success: "Decommission this device. This process can take 4 to 8 hours if the device is powered on and connected to internet. For support status, refer to action Id: "
            }
        },
        EditLocation: {
            Title: "Edit Device Location",
            ActionName: "Edit",
            Message: {
                Success: "Successfully updated the device location."
            }
        }
    },

    UpdateDeviceKeys: {
        facility: "facility",
        slot: "slot",
        locker: "locker",
        room: "room",
        facilityInvalidMessage: "Cannot be Empty",
        slotInvalidMessage: "Enter NA if not available",
        lockerInvalidMessage: "Enter NA if not available",
        roomInvalidMessage: "Cannot be Empty",
        UpdateDeviceSuccessMessage: "Device updated successfully for Serial Number: {0}",
        UpdateDeviceFailedInternalServerErrorMessage: "Unknown error occurred. Please contact {0}",
        UpdateDeviceFailedPreconditionErrorMessage: "No change update found for the device {0}. Refresh and try again to edit",
        UpdateDeviceFailedGenericMessage: "Device update failed with error: {0}",
        UpdateDeviceFailedBadRequestGenericMessage: "The device was not updated due to: {0}",
        UpdateDeviceFailedNotFoundErrorMessage: "device can't be assigned to the unknown group. Please contact {0}",
        UpdateDeviceFailedUnauthorizedErrorMessage: "User does not have required roles to update device location. Please contact {0}"
    },

    DeviceHealth: {
        PageTitle: "Device Health",
        DeviceState: "Device State",
        Recommendations: "Recommendations",
        BatteryStatus: "Battery Status",
        OS: 'Operating System',
        HardwareModel: 'Hardware Make/Model',
        StorageCapacity: 'Storage Capacity',
        StorageRemaining: 'Storage Remaining',
        LastHeartbeat: 'Last Activity Date',
        LastWindowsUpdate: 'Last Windows Update',
        NoDevicesFound: "No devices were found",
        UnAuthorized: "Please login as a site administrator"
    },
    DeviceKey: {
        SerialNumber: 'deviceSerialNumber',
        AssetId: 'deviceAssetId',
        DeviceAsset: 'deviceAsset',
        DeviceState: 'deviceState',
        DeviceStatus: 'deviceStatus',
        AutoPilotRegistrationDate: 'apRegistrationDateUTCString',
        Recommendations: 'recommendations',
        BatteryStatus: 'batteryStatus',
        Scenario: 'deviceType',
        Metro: 'metro',
        Campus: 'campus',
        Facility: 'facility',
        Room: 'room',
        Locker: 'locker',
        Slot: 'slot'
    },

    DeviceOnboarding: {
        UnAuthorized: "Please login as an device manager",
        DeviceOnboardSuccessMessage: "{0}",
        DeviceOnboardFailedInternalServerErrorMessage: "Unknown error occurred. Please contact {0}",
        DeviceOnboardFailedSNoExistsMessage: "{0}",
        DeviceOnboardFailedNotInAutopilotMessage: "{0}",
        DeviceOnboardFailedFacilityNotfoundMessage: "{0}",
        DeviceOnboardFailedGenericMessage: "Device onboarding failed with error: {0}",
        DeviceOnboardFailedBadRequestGenericMessage: "The device is not onboarded beacause of the following error: {0}",
        InvalidScenarioVariantMessage: "{0} is invalid"
    },
    CreateSingleUser: {
        firstName: "firstName",
        lastName: "lastName",
        email: "email",
        role: "role",
        scenario: "scenario",
        company: "company",
        firstNamePlaceholder: 'Please enter the first name',
        lastNamePlaceholder: 'Please enter the last name',
        emailPlaceholder: 'Please enter the email',
        rolePlaceholder: 'Please select the role',
        scenarioPlaceholder: 'Please select the scenario/s',
        companyPlaceholder: 'Please select the company',
        firstNameInvalidMessage: ' Cannot be Empty',
        lastNameInvalidMessage: ' Cannot be Empty',
        emailInvalidMessage: 'Email is Invalid',
        roleInvalidMessage: 'Role is Invalid',
        scenarioInvalidMessage: 'Scenario is Invalid',
        companyInvalidMessage: 'Company is Invalid',
        toolTipContent: "Data Center Access Tool is a mechanism to provision and approve physical access.Most users will need some sort of physical access.",
        UnAuthorized: "Please login as an account manager",
        UserCreationSuccessMessage: "User successfully created and the account details have been sent to: {0}",
        UserCreationSuccessButErrorInSendingAccountDetailsMessage: "User successfully created but there was an error sending the account details to: {0}. Please contact {1}",
        UserCreationFailedInternalServerErrorMessage: "Unknown error occurred. Please contact {0}",
        UserCreationFailedUPNExistsMessage: "Another user with same email already exists.",
        UserCreationFailedScenariosInvalidMessage: "Roles specified are invalid. Please contact {0}",
        UserCreationFailedEmailDomainInvalidMessage: "Email domain specified is not valid",
        UserCreationFailedNoDCATMessage: "User does not have DCAT approval. Please contact {0}",
        UserCreationFailedNoCOIMessage: "User is not a member of COI. Please contact {0}",
        UserCreationFailedGenericMessage: "User creation failed with error: {0}",
        UserCreationFailedBadRequestGenericMessage: "The user was not created: {0}.",
        UserCreationFailedInvalidRolesMessage: "The user does not have ScenarioOwner or OrganizationAdministrator role",
        UserWithNoDCATApprovalMessage: "User does not have any approved DCAT requests",
        UserWithNoCOIMessage: "User is not a member of CO+I"
    },
    UserElevation: {
        reasonLabel: "Justification for elevation",
        reasonPlaceHolder: "Please enter your reason for elevation. Maximum supported text is 500 characters.",
        roleLabel: "Role",
        rolePlaceHolder: "Please select a value",
        privilege: "Privilege",
        UnAuthorized: "User does not have any eligible roles",
        UserElevationSuccessMessage: "User account elevated for the next {0} hours.",
        UserRefreshLoginMessage: "Incase the admin rights do not reflect on your device. Please refresh your login using the Refresh User Login tool on your Valyrian device",
        UserElevationTitle: "Elevate your privilege role"
    },
    Notification: {
        NotificationStatusSuccess: "Added",
        NotificationStatusFailure: "Failed"
    },

    FileUpload: {
        FileUploadSuccess: "File uploaded successfully. Please save the file Id : {0}.",
        UnknownError: "Unknown Error. Could not upload the file.",
        UploadFileEmpty: "Select a file to upload",
        FileUploadTitle: "Upload File",
        FileUPloadTitleSubText: "Max size supported is 500 MB.",
        FileUploadFailure: "Please contact Valyrian Support with Error Id : {0}",
        FileSizeIsNotSupported: "The uploaded file size is not supported. The max file supporte is 100MB.",
        FileSizeIsZero: "The uploaded file is empty"
    },

    ScenarioManagement: {
        VMDEployFailedInternalServerErrorMessage: "VM Deployment failed. Please refresh and try again.",
        VMDeployFailedInvalidMessage: "VM Deployment cannot be completed due to internal issue. Please contact Team Valyrian.",
        VMDeployFailedGenericMessage: "{0}",
        VMPlaceholder: 'Please select the VM'
    },

    Marketing: {
        PageTitle: "Secure Workstations",
        RolloutProgress: "Project Valyrian devices are currently being utilized in Microsoft Production areas, Critical Environments and Security Operation Centers.Many non- FTEs / vendors have opted in to use these secured devices across Class A- C datacenters in addition to FTEs such as those working as Critical Environment technicians and break-fix technicians utilizing SMTK tool kit.",
        WhySecureDevices_title: "Why we need secure devices",
        WhySecureDevices_text: "Microsoft datacenters are supported by complex ecosystem of non-full-time (visiting technicians & on-site vendors) and full-time technicians. Prior to Project Valyrian, all non-full time employees would bring in vendor-company owned, managed and potentially unsecured devices to Microsoft datacenters and connect them to production systems to set up or perform maintenance on the systems. Given these devices were not Microsoft managed devices, Microsoft had limited governance and monitoring on these devices which potentially exposed Microsoft to security risks. Additionally, full-time technicians used SAW which hindered their productivity since SAW's were never designed with datacenter persona in mind. Due to these reasons, Project Valyrian was born to provide a secured device solution for datacenter personnel.",
        WhatAreSecureDevices_title: "What are secured devices",
        WhatAreSecureDevices_text: 'Project Valyrian devices are cloud based, zero-trust, sharable, and secure devices. They rely on existing Microsoft technologies such as Intune, AAD, Defender ATP, and protect from credential theft, malware, unsecure 3rd party applications, and common social engineering attacks. These devices are:',
        FeaturePersona_title: "Designed for DC persona",
        FeaturePersona_text: "Needs of data center technicians are core to this secure device solution.",
        FeatureHardware_title: "Hardware agnostic",
        FeatureHardware_text: "Support for a number of hardware makes/models. Includes Dell, Lenovo, MS Surface.",
        FeatureApps_title: "Customizable app layer",
        FeatureApps_text: "Increased flexibility to add any application on device post passing security review.",
        FeatureMonitoring_title: "Full health & security monitoring",
        FeatureMonitoring_text: "This ensures that devices are compliant and secure all the time.",
        SharableAcrossUsers_title: "Sharable across users",
        SharableAcrossUsers_text: "Users of same persona can share devices",
        EasyDataTransfers_title: "Easy data transfers",
        EasyDataTransfers_text: "OneDrive solution to allow easy data access",
        Announcements_title: "Announcements",
        Announcements_update1: 'Secure Devices now shipped to C+E environments',
        Announcements_update2: "Secure Devices now in SAW ecosystem",
        Announcements_update3: "More than 36 Secure devices deployed and have 124 unique users across all class A DCs in CE pilot",
        Announcements_update4: "New PIM tool Available on your secured devices",
        Trial_title: 'Reach out for trial',
        Trial_text: 'Curious to learn more about secure device project? Wondering if this flexible solution can work for your scenario? '
    },

    UpdateUserKeys: {
        firstName: "firstName",
        lastName: "lastName",
        displayName: "displayName",
        metro: "metro",
        scenarioPlaceholder: 'Please select the scenario/s',
        rolesPlaceholder: 'Please select the role',
        metroPlaceholder: 'Please select the metro',
        firstNamePlaceholder: 'Please enter the first name',
        lastNamePlaceholder: 'Please enter the last name',
        displayNamePlaceholder: 'Please enter the display name',
        firstNameInvalidMessage: ' Cannot be Empty',
        lastNameInvalidMessage: ' Cannot be Empty',
        displayNameInvalidMessage: ' Cannot be Empty',
        UnAuthorized: "Please login as an account manager",
        UpdateUserSuccessMessage: "User updated successfully for UPN: {0}",
        UpdateUserFailedInternalServerErrorMessage: "Unknown error occurred. Please contact {0}",
        UpdateUserFailedPreconditionErrorMessage: "No change update found for the user {0}. Refresh and try again to edit",
        UpdateUserFailedGenericMessage: "User update failed with error: {0}",
        UpdateUserFailedBadRequestGenericMessage: "The user was not updated due to: {0}",
        UpdateUserFailedNotFoundErrorMessage: "User cann't be assinged to the unknown group. Please contact {0}"
    },

    DisableUserKeys: {
        DisableUserSuccessMessage: "User disabled successfully for UPN: {0}",
        DisableUserFailedInternalServerErrorMessage: "User disable failed with error: {0}",
        DisableUserFailedGenericMessage: "User disable failed with error: {0}",
        DisableUserFailedBadRequestGenericMessage: "The user was not disabled due to: {0}",
        DisableUserFailedNotFoundErrorMessage: "User disable failed with error: {0}",
        DisableUserFailedAdminRoleErrorMessage: "User disable failed as the user is part of the admin role. Please contact {0}"
    },

    FrameworkList: {
        PageNavCurrentPage: "Page {0} of {1}",
        Previous: "Previous",
        Next: "Next",
        Refresh: "Refresh",
        Export: {
            ExportCommand: "Export",
            ExportInProgress: "Exporting...",
            ExportFinished: "Export finished!",
            ExportError: "An error occurred while exporting the data.",
            ExportConfirmationWarning: "Are you sure you want to export all device inventory data?",
            DownloadExportData: "Click here to download"
        }
    }
};