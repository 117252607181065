import * as React from "react";
import { Nav, INavLinkGroup } from '@fluentui/react/lib/Nav';
import ResourceManager from 'Resources/ResourceManager';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { Constants } from 'Utilities/Constants';

interface NavProp {
    isAuthenticated: boolean;
    authButtonMethod: any;
    user: any;
}

let userHasAllPermissions = false;

const NavItem_CreateUser = ResourceManager.GetString(ResourceStrings.NavBar.CreateUser);
const NavItem_UserElevation = ResourceManager.GetString(ResourceStrings.NavBar.UserElevation);
const NavItem_ManagerUsers = ResourceManager.GetString(ResourceStrings.NavBar.ManageUsers);
const NavItem_ManagerScenarios = ResourceManager.GetString(ResourceStrings.NavBar.ManageScenarios);

const NavItem_DeviceHealth = ResourceManager.GetString(ResourceStrings.NavBar.DeviceHealth);
const NavItem_DeviceInventory = ResourceManager.GetString(ResourceStrings.NavBar.DeviceInventory);
const NavItem_DeviceOnboarding = ResourceManager.GetString(ResourceStrings.NavBar.DeviceOnboarding);
const NavItem_FileUpload = ResourceManager.GetString(ResourceStrings.NavBar.FileUpload);

export const getNavItemKey = (NavItem: string) => {
    return NavItem.split(" ").join("");
};

export const NavSection: React.FunctionComponent<NavProp> = (props) => {

    if (props.isAuthenticated && props.user.roles) {
        userHasAllPermissions = Authorization.AuthorizeUser(props.user.roles, [
            AuthorizedRole.SystemAdmin,
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioOwner,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.SupportUser
        ]);
    } 

    const navLinkGroups: INavLinkGroup[] = [
        {
            name: ResourceManager.GetString(ResourceStrings.NavBar.Users),
            links: [
                    {
                        key: getNavItemKey(NavItem_CreateUser),
                        name: NavItem_CreateUser,
                        url: './CreateSingleUser',
                        iconProps: { iconName: "AddFriend" }
                    },
                    {
                        key: getNavItemKey(NavItem_UserElevation),
                        name: ResourceManager.GetString(ResourceStrings.NavBar.ElevateRole),
                        url: './UserElevation',
                        iconProps: { iconName: "Signin" }
                    },
                    {
                        key: getNavItemKey(NavItem_ManagerUsers),
                        name: ResourceManager.GetString(ResourceStrings.NavBar.ManageUsers),
                        url: './UserManagement',
                        iconProps: { iconName: "ManagerSelfService" }
                    }
            ]
        },
        {
            name: ResourceManager.GetString(ResourceStrings.NavBar.Devices),
            links: [
                {
                    key: getNavItemKey(NavItem_DeviceHealth),
                    name: NavItem_DeviceHealth,
                    url: './DeviceHealth',
                    iconProps: { iconName: "Health" }
                },
                {
                    key: getNavItemKey(NavItem_DeviceInventory),
                    name: NavItem_DeviceInventory,
                    url: './DeviceInventory',
                    iconProps: { iconName: "Devices3" }
                },
                {
                    key: getNavItemKey(NavItem_DeviceOnboarding),
                    name: NavItem_DeviceOnboarding,
                    url: './DeviceOnboarding',
                    iconProps: { iconName: "LaptopSelected" }
                }
            ]
        },
        {
            name: ResourceManager.GetString(ResourceStrings.NavBar.Files),
            links: [
                {
                    key: getNavItemKey(NavItem_FileUpload),
                    name: NavItem_FileUpload,
                    url: './FileUpload',
                    iconProps: { iconName: "OpenFile" }
                }
            ]
        },
        {
            name: ResourceManager.GetString(ResourceStrings.NavBar.Scenarios),
            links: [
                {
                    key: getNavItemKey(NavItem_ManagerScenarios),
                    name: NavItem_ManagerScenarios,
                    url: './ScenarioManagement',
                    iconProps: { iconName: "Devices3" }
                }
            ]
        }
    ];

    return (
        <div>
            <Nav groups={navLinkGroups} />
        </div>
    );
};