import * as React from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import type { PagingParams } from "Components/Framework/CommonList/ListPaging";

import { CommonList, CommonListCache, CommonListProps } from "Components/Framework/CommonList/CommonList";
import { ScenarioApiModel, ScenarioUIModel, EmptyScenarioApiModel } from "Models/ScenarioResponseModel";
import { ScenarioDetails } from './ScenarioDetails';
import { ScenarioService } from "Services/ScenarioService";
import { TracingService } from "Services/TracingService";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { UserContext } from 'Auth/UserContext';
import ResourceManager from "Resources/ResourceManager";
import { ResourceStrings } from "Resources/ResourceKeys";
import {
    Button,
    TableColumnDefinition,
    createTableColumn
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { FilterValue } from "Components/Framework/CommonList/Filters/DynamicFilterTypes";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { IDropdownOption, Link } from '@fluentui/react';
import { Scenario } from "../../../Models/ScenarioResponseModel";
import { UserManagementService } from "../../../Services/UserManagementService";

const Component_Name = 'Scenario List';
const debugging = false;
const debug = (message: string) => {
    if (debugging) {
        console.log("[" + Component_Name + "] " + message);
    }
};

let tracingService = TracingService.getInstance();
let userManagementService = UserManagementService.getInstance();

let AlertMessage = ResourceManager.GetString(ResourceStrings.UpdateUserKeys.UnAuthorized);
let Error_Search = 'Enter at least 3 characters of serial number to begin search'; // localize
var scenarios: Scenario[];
var scenariosByUser: Scenario[] = [];
let loggedInUserDetail: any;

interface IScenarioListState {
    items: any[];
    isLoading: boolean;
    isRefreshing: boolean;
    isError: boolean;
    totalItemCount: number;
    selectedItem: ScenarioApiModel;
    selectedItemOpen: boolean;
    scenarioFilterOptions: FilterValue[];
    cacheFull: boolean;
    refreshToken: number;
    exportItems: ScenarioUIModel[];
};

export class ScenarioManagementPage extends React.Component<{}, IScenarioListState> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Scenario Management",
        authorizedRoles: [
            AuthorizedRole.SystemAdmin,
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.ScenarioOwner
        ]
    };

    private isError = false;
    private paging: PagingParams = {
        pageSize: 100,
        batchSize: 600,
        token: "",
        totalItemCount: 0
    };

    private scenarioCache: CommonListCache = new CommonListCache();

    constructor(props: {}) {
        super(props);

        this.state = {
            items: [],
            isLoading: true,
            isRefreshing: false,
            isError: false,
            totalItemCount: 0,
            selectedItem: EmptyScenarioApiModel,
            selectedItemOpen: false,
            scenarioFilterOptions: [],
            cacheFull: false,
            refreshToken: 0,
            exportItems: []
        };
    }

    static contextType = UserContext;

    private cacheIsFull(): boolean {
        return this.scenarioCache.Read().length === this.paging.totalItemCount;
    };

    private columns: TableColumnDefinition<ScenarioUIModel>[] = [
        createTableColumn<ScenarioUIModel>({
            columnId: "scenarioId",
            renderHeaderCell: () => {
                return "Scenario";
            },
            renderCell: (item) => {
                return (<Link onClick={() => this.OnItemClicked(item)}>{item.name}</Link>);
            },
            compare: (a: ScenarioUIModel, b: ScenarioUIModel) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            }
        }),
        createTableColumn<ScenarioUIModel>({
            columnId: "description",
            renderHeaderCell: () => {
                return "Scenario Description";
            },
            renderCell: (item) => {
                return item.description;
            },
            compare: (a: ScenarioUIModel, b: ScenarioUIModel) => {
                return a.description.toLowerCase() < b.description.toLowerCase() ? -1 : 1;
            }
        })
    ];

    private RefreshListAsync = async () => {
        this.scenarioCache.Clear();
        this.setState({
            isLoading: true,
            isRefreshing: true,
            items: []
        });

        await this.fetchScenariosAsync().then(scenarios => {
            if (scenarios.length > 0) {
                this.scenarioCache.Append(scenarios);
            }

            this.setState({
                totalItemCount: this.paging.totalItemCount,
                isLoading: false,
                isRefreshing: false,
                selectedItem: EmptyScenarioApiModel,
                selectedItemOpen: false,
                isError: this.isError,
                cacheFull: this.scenarioCache.Read().length === this.paging.totalItemCount
            });
        });
    };

    private async fetchScenariosAsync() {
        const user = this.context;
        scenarios = await ScenarioService.getInstance().GetScenariosAsync(user.IdToken);
        if (user.roles.includes(AuthorizedRole.OrganizationAdministrator) || user.roles.includes(AuthorizedRole.SystemAdmin)) {
            this.setState({
                items: scenarios
            });
        } else if (user.roles.includes(AuthorizedRole.ScenarioOwner)) {
            scenariosByUser = await this.GetUserByAadId(scenarios);
            var scenarioOwnerScenario = scenariosByUser.filter((item, index, self) =>
                index === self.findIndex((scenario) => scenario.id === item.id)
            );
            this.setState({
                items: scenarioOwnerScenario
            });
        } else {
            this.setState({
                items: []
            });
        }
        return scenarios;
    };


    GetUserByAadId = async (scenarioMasterData: Scenario[]) => {
        const user = this.context;
        var scenarioDetailsMap = this.MapScenarioData(scenarioMasterData);
        await userManagementService
            .GetUserByAadObjectId(
                user.IdToken,
                user.userObjectID
            ).then((results) => {
                loggedInUserDetail = results.message;
                loggedInUserDetail.scenarioIds.forEach((scenario: string) => {
                    scenariosByUser.push(scenarioDetailsMap.get(scenario));
                });
            });
        return scenariosByUser;
    };

    MapScenarioData = (scenarioMasterData: Scenario[]) => {
        var scenarioIdToNameMap = new Map();
        scenarioMasterData.forEach((option) => {
            var scenarioObject = new Scenario(option.id, option.name, option.description, option.variants);
            scenarioIdToNameMap.set(option.id, scenarioObject);
        });
        return scenarioIdToNameMap;
    };

    private async loadingDelay() {
        await new Promise(resolve => setTimeout(resolve, 100));
    };

    private doSearch = (value: string) => {
        // Cancelling the search forces a full refresh
        if (value === "") {
            this.RefreshListAsync();
        } else if (value.length < 3) {
            this.setState({ isError: true });
            AlertMessage = Error_Search;
        } else {
            this.setState({
                isLoading: true,
                isRefreshing: true,
                items: []
            });

            // Call API everytime
            this.loadingDelay().then(async res => {
                let filteredScenarios: ScenarioApiModel[] = [];
                filteredScenarios = this.scenarioCache.Read().filter(scenario => scenario.name !== null ? scenario.name.toUpperCase().includes((value.toUpperCase())) : null);

                this.paging.totalItemCount = filteredScenarios.length;
                this.setState({
                    items: filteredScenarios,
                    totalItemCount: this.paging.totalItemCount,
                    isLoading: false,
                    isRefreshing: false
                });
            });
        }
    };

    private CloseSelectedItem = (ev: React.MouseEventHandler<HTMLDivElement>) => {
        this.setState({
            selectedItem: EmptyScenarioApiModel,
            selectedItemOpen: false
        });
        this.RefreshListAsync();
    };

    private OnItemClicked = (item: ScenarioUIModel): void => {
        const cachedResult = this.scenarioCache.Read().filter((scenario) => (scenario as ScenarioApiModel).id === item.id);
        if (cachedResult.length === 0) {
            cachedResult.push(EmptyScenarioApiModel);
        }

        if (cachedResult[0] !== this.state.selectedItem && !this.state.selectedItemOpen) {
            this.setState({
                selectedItem: cachedResult[0],
                selectedItemOpen: true
            });
        }
        else {
            this.setState({ selectedItemOpen: false });
        }
    };

    private CloseMessageBox = () => {
        AlertMessage = "trr";
        this.setState({
            isError: false
        });
    };

    componentDidMount() {
        this.fetchScenariosAsync();
        this.RefreshListAsync();
    };

    render() {
        const listProps: CommonListProps = {
            dataGridProps: {
                items: this.state.items,
                columns: this.columns,
                sortable: this.state.cacheFull
            },
            itemCountName: "Scenarios",
            isLoading: this.state.isLoading,
            isRefreshing: this.state.isRefreshing,
            onRefresh: this.RefreshListAsync,
            refreshToken: this.state.refreshToken,
            
            searchProps: {
                columnId: this.columns[0].columnId.toString(),
                useCache: true,
                onSearch: this.doSearch,
                placeholder: "Search Scenario"
            }
        };

        if (this.state.selectedItemOpen) {
            return (
                <Portal.CommonPage {...this.pageProps}>
                    <Fabric>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <ScenarioDetails scenario={this.state.selectedItem} onClose={this.CloseSelectedItem} />
                        </div>
                    </Fabric>
                </Portal.CommonPage>
            );
        }

        return (
            <Portal.CommonPage {...this.pageProps}>
                <div className="list-error" style={{ display: this.state.isError ? "block" : "none" }}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={this.CloseMessageBox}
                        appearance="transparent"
                        icon={<DismissRegular />}
                        size="small"
                    />
                    {AlertMessage}
                </div>
                <CommonList {...listProps}></CommonList>
            </Portal.CommonPage>
        );
    };
}