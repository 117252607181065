import { ApplicationInsightsLogger } from './Logger';
import { Constants } from 'Utilities/Constants';
import { TracingService } from './TracingService';
import * as Common from "Components/Common/ValyrianCommonComponents";
import { VMMetaDataModel, EmptyVMMetaDataModel } from '../Models/VMMetaDataModel';
import UpdateVMMetadata from '../Models/UpdateVMMetadata';

const constants = Constants.getInstance();
const Component_Name = 'Device Inventory Service';
let tracingService = TracingService.getInstance();

export class ScenarioManagementService {
    private static instance: ScenarioManagementService;

    private constructor() {
    }

    public static getInstance = () => {
        if (!ScenarioManagementService.instance) {
            ScenarioManagementService.instance = new ScenarioManagementService();
        }
        return ScenarioManagementService.instance;
    };


    public GetVMListByScenarioAsync = async (token: string, scenarioId: string) => {
        tracingService.trace(Component_Name, 'GetVMListByScenario');
        const GetVmDetailsEndpoint = Common.Helper.stringFormat(constants.GetVmDetailsEndpoint, scenarioId);

        try {
            const response = await this.GetVMListByScenarioCall(GetVmDetailsEndpoint, token);
            const data = await response.json();

            if (response.ok) {
                var getVmResponse: VMMetaDataModel[] = data;
                var results = new Array<VMMetaDataModel>();
                if (getVmResponse) {
                    getVmResponse.forEach(s => results.push(new VMMetaDataModel(s.id, s.scenario, s.prevVersion, s.currVersion, s.fileName, s.sha, s.vmStatus, s.createdTime, s.updatedBy, s.updatedTime, s.userVersion, s.etag, s.error)));
                }
                console.log(results);
                return results;
            }
            else {
                const errorMsg = (data && data.error.message) || response.statusText;
                return new Array<VMMetaDataModel>();
            }
        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);

            return null;
        };
    };

    public DeployVMAsync = async (token: string, id: string, scenario: string, vmStatus: string, fileName: string, etag: string): Promise<VMMetaDataModel> => {
        tracingService.trace(Component_Name, 'DeployVMAPICall');
        let updateVMOutput: VMMetaDataModel = EmptyVMMetaDataModel;
        const DeployVmEndPoint = Common.Helper.stringFormat(constants.DeployVmEndpoint, id);
        const deployVMRequest = UpdateVMMetadata.getUpdateVMMetadataPatchModel(
            this.getUpdateVMMetadataRequest(id, scenario, vmStatus, fileName, etag));

        try {
            const response = await this.DeployVMApiCall(DeployVmEndPoint, token, deployVMRequest, etag);
            const data = await response.json();
            let vmMetaDataModelResponse: VMMetaDataModel;
            vmMetaDataModelResponse = data;
            updateVMOutput = ScenarioManagementService.UpdateVMDeployResponseToUpdateVMMetadataPortalUI(vmMetaDataModelResponse);

        }
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
        }
        return updateVMOutput;
    };

    private DeployVMApiCall = async (UpdateDeviceEndPoint: string, token: string, updateVmMetadataRequest: UpdateVMMetadata, eTag: string) => {
        return await fetch(UpdateDeviceEndPoint, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'PATCH, DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token,
                'If-Match': eTag
            },
            body: JSON.stringify(updateVmMetadataRequest)
        });
    };

    private getUpdateVMMetadataRequest = (id: string, scenario: string, vmStatus: string, fileName: string, etag: string) => {

        return new UpdateVMMetadata(id, scenario, vmStatus, fileName, etag);
    };

    private static UpdateVMDeployResponseToUpdateVMMetadataPortalUI = (vmMetaDataModel: VMMetaDataModel) => {
        return new VMMetaDataModel(vmMetaDataModel.id, vmMetaDataModel.scenario, vmMetaDataModel.prevVersion, vmMetaDataModel.currVersion, vmMetaDataModel.fileName, vmMetaDataModel.sha, vmMetaDataModel.vmStatus, vmMetaDataModel.createdTime, vmMetaDataModel.updatedBy, vmMetaDataModel.updatedTime, vmMetaDataModel.userVersion, vmMetaDataModel.etag, vmMetaDataModel.error);
    };

    private GetVMListByScenarioCall = async (GetVMByScenarioEndPoint: string, token: string) => {
        return await fetch(GetVMByScenarioEndPoint, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Methods': 'PATCH, DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                'correlationId': constants.CorrelationID,
                Authorization: constants.Bearer + token
            }
        });
    };
}