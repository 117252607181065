
export class UpdateVMMetadata {
    readonly id: string;
    readonly scenario: string;
    readonly vmStatus: string;
    readonly fileName: string;
    readonly etag: string;

    constructor(id: string, scenario: string, vmStatus: string, fileName: string, etag: string) {
        this.id = id;
        this.scenario = scenario;
        this.vmStatus = vmStatus;
        this.fileName = fileName;
        this.etag = etag;
    }

    static getUpdateVMMetadataPatchModel(updateVMMetadata: UpdateVMMetadata) {

        return {
            id: updateVMMetadata.id,
            vmStatus: updateVMMetadata.vmStatus,
            scenario: updateVMMetadata.scenario,
            etag: updateVMMetadata.etag,
            fileName: updateVMMetadata.fileName
        };
    }
}

export default UpdateVMMetadata;