import * as React from 'react';
import { ActionButton, CommandBar, ICommandBarItemProps, IIconProps } from '@fluentui/react';
import { ScenarioApiModel, ScenarioUIModel, ScenarioMap } from 'Models/ScenarioResponseModel';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { ScenarioService } from 'Services/ScenarioService';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import ResourceManager from 'Resources/ResourceManager';
import "scss/site.css";
import { VMDeploymentForm } from './DeployVMForm';

const Component_Name = 'Scenario Details';
const DetailName_ScenarioName = ResourceManager.GetString(ResourceStrings.Scenarios.Scenario);
const DetailName_Description = ResourceManager.GetString(ResourceStrings.Scenarios.Description);
const DetailName_ScenarioOwner = ResourceManager.GetString(ResourceStrings.Scenarios.ScenarioOwner);
const Command_VMDeployment = ResourceManager.GetString(ResourceStrings.ScenarioAction.VMDeployment.ActionName);

let tracingService = TracingService.getInstance();
let scenarioService = ScenarioService.getInstance();
const BackIcon: IIconProps = { iconName: 'ChromeBack' };

interface IScenarioDetailsProps {
    scenario: ScenarioApiModel;
    onClose: any;
}

interface IScenarioDetailsState {
    scenario: ScenarioUIModel;
    VMDeploy: boolean;
    actionsDisabled: boolean;
    actionButtons: ICommandBarItemProps[];
    decommission: boolean;
    editing: boolean;
}

export class ScenarioDetails extends React.Component<IScenarioDetailsProps, IScenarioDetailsState> {
    private scenario: ScenarioUIModel;
    constructor(props: IScenarioDetailsProps) {
        super(props);
        this.scenario = ScenarioMap.ApiToUI(props.scenario);
        this.state = {
            scenario: this.scenario,
            VMDeploy: false,
            actionsDisabled: false,
            actionButtons: this.CreateCommandBarItems(),
            decommission: false,
            editing: false
        };
    }

    private PopulateScenarioDetails() {
        this.setState({
            scenario: this.scenario,
            actionButtons: this.CreateCommandBarItems(),
            editing: false,
            VMDeploy: false
        });
    };

    static contextType = UserContext;

    private RefreshScenarioAsync = async() => {
        const user = this.context;
        if (user.IdToken) {
            await scenarioService.GetScenariosAsync(user.IdToken)
                .then(results => {
                    this.PopulateScenarioDetails();
                })
                .catch(error => {
                    tracingService.trace(Component_Name, error);
                    console.log(error);
                });
        }
    };

    private OpenVMDeployForm = () => {
        this.setState({ VMDeploy: true });
    };

    private CloseForm = () => {
        this.RefreshScenarioAsync();
    };

    private CreateCommandBarItems(): ICommandBarItemProps[] {
        return [
            {
                key: 'vmdeployment',
                text: Command_VMDeployment,
                iconProps: { iconName: 'Devices2' },
                onClick: () => (this.OpenVMDeployForm())
            }
        ];
    }

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying sceanrio details');
        this.PopulateScenarioDetails();
    }

    private renderDetails() {
        return (
            <div style={{ width: '90%', margin: '1rem auto', position: 'relative' }}>

                <h1 className="title" style={{ marginBottom: '0.1rem' }}>
                    {this.props.scenario.name}
                </h1>
                <h5 className="header" style={{ marginBottom: '0.25rem' }}>Properties</h5>

                <div style={{ width: '100%', margin: '1rem auto', position: 'relative', padding: 0, borderBottom: '1px solid #ccc' }}>
                    <CommandBar items={this.state.actionButtons} />
                </div>

                <div id="device-details" style={{ width: '97%', margin: '2rem auto' }} >
                    <h4>{ResourceManager.GetString(ResourceStrings.Common.Overview)}</h4>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <div style={{ flex: "1 0 50%" }}>
                            <h5 className="header">{DetailName_ScenarioName}</h5>
                            <p>{this.state.scenario.name}</p>

                            <h5 className="header">{DetailName_Description}</h5>
                            <p>{this.state.scenario.description}</p>

                            <h5 className="header">{DetailName_ScenarioOwner}</h5>
                            <p>{this.context.userPrincipalName}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public render(): JSX.Element { 
        return (
            <div style={{ backgroundColor: '#fefefe', minHeight: '500px', position: 'relative', width: '100%', margin: 'auto', paddingBottom: '4rem' }}>
                <div style={{ textAlign: 'left', paddingTop: '1rem', marginLeft: '2rem' }}>
                    <ActionButton onClick={this.props.onClose} iconProps={BackIcon} disabled={this.state.VMDeploy}>{ResourceManager.GetString(ResourceStrings.Scenarios.ScenarioList)}</ActionButton>
                </div>
                {this.state.VMDeploy ? <VMDeploymentForm scenario={this.props.scenario} onClose={this.CloseForm} /> : this.renderDetails()}

            </div>
        );
    }
}