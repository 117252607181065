import * as React from 'react';
import {
    Label,
    IDropdownOption,
    Dropdown,
    PrimaryButton,
    DefaultButton,
    IStackTokens,
    Stack
} from '@fluentui/react';
import {
    Checkbox,
    MessageBar,
    MessageBarType,
    mergeStyles
} from "office-ui-fabric-react";
import * as Common from "Components/Common/ValyrianCommonComponents";
import { ResourceStrings } from 'Resources/ResourceKeys';
import { Scenario, ScenarioApiModel } from "../../../Models/ScenarioResponseModel";
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import Loading from "Images/Loading.gif";
import ResourceManager from 'Resources/ResourceManager';
import "../../../scss/common.css";
import "scss/site.css";
import { Authorization, AuthorizedRole } from '../../../Auth/Authorization';
import { ScenarioManagementService } from '../../../Services/ScenarioManagementService';
import { VMHelper, VMMetaDataModel } from '../../../Models/VMMetaDataModel';

const Component_Name = 'VM deployment Details';
const DeployVM_SuccessMessage = ResourceManager.GetString(ResourceStrings.ScenarioAction.VMDeployment.Message.Success);
const VMDeploymentFormTitle = ResourceManager.GetString(ResourceStrings.ScenarioAction.VMDeployment.Title);
const VMPlaceholder = Common.ResourceManager.GetString(
    Common.ResourceStrings.ScenarioManagement.VMPlaceholder
);
const FormDescription = ResourceManager.GetString(ResourceStrings.ScenarioAction.VMDeployment.Description);
const VMNameElementId = "VMNames";

const checkBoxStyles = {
    text: {
        fontWeight: "500"
    }
};

let tracingService = TracingService.getInstance();
let scenarioManagementService = ScenarioManagementService.getInstance();

interface IVMDeploymentFormProps {
    scenario: ScenarioApiModel;
    onClose: (success: boolean) => any;
}

interface IVMDeploymentFormState {
    selectedVM: string;
    isLoading: boolean;
    error: boolean;
    showMessageBar: boolean;
    apiResponse: string;
    apiSuccess: boolean;
    vmOptions: IDropdownOption[];
    showConsentForProd: boolean;
    isProdDeployConsent: boolean;
    readyToDeploy: boolean;
    promoteTo: string;
    vmState: string;
    showVmDetails: boolean;
    etag: string;
    vmName: string;
    scenarioId: string;
    scenarioName: string;
    userVersion: string;
    errorMessage: string;
    errorCode: string;
    vmDeployApiCall: boolean;
}

export class VMDeploymentForm extends React.Component<IVMDeploymentFormProps, IVMDeploymentFormState> {
    private vmMetaData: VMMetaDataModel[] = [];
    private Constant = Common.Constants.getInstance();
    private newResource: any = {};
;
    constructor(props: IVMDeploymentFormProps) {
        super(props);

        this.state = {
            selectedVM: "",
            isLoading: false,
            error: false,
            showMessageBar: false,
            apiResponse: "",
            apiSuccess: false,
            vmOptions: [],
            showConsentForProd: false,
            isProdDeployConsent: false,
            readyToDeploy: false,
            promoteTo: "",
            vmState: "",
            showVmDetails: false,
            etag: "",
            vmName: "",
            scenarioId: "",
            scenarioName: "",
            userVersion: "",
            errorMessage: "",
            errorCode: "",
            vmDeployApiCall: false
        };

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.UserFriendlyErrorMessageMapping =
            this.UserFriendlyErrorMessageMapping.bind(this);
    }

    static contextType = UserContext;

    private PromoteVMAsync = async () => {
        const user = this.context;
        await scenarioManagementService
            .DeployVMAsync(user.IdToken, this.state.scenarioId, this.state.scenarioName, this.state.vmState, this.state.vmName, this.state.etag)
            .then(results => {
                console.log(results);
                if (results.error) {
                    console.log(results.error.code);
                    this.setState({
                        apiResponse: results.error.code,
                        showMessageBar: true,
                        error: true,
                        isLoading: false,
                        errorMessage: results.error.message,
                        errorCode: results.error.code,
                        vmDeployApiCall: true
                    });
                } else if (results.id === this.state.scenarioId) {
                    this.setState({
                        apiResponse: results.id,
                        showMessageBar: true,
                        error: false,
                        isLoading: false,
                        errorMessage: "",
                        errorCode: "",
                        vmDeployApiCall: true
                    });
                }
                else {
                    tracingService.trace(Component_Name, "Deploy VM unsuccessful");
                    this.setState({
                        errorMessage: "Could not deploy the VM. Please constact Valyrian",
                        apiResponse: "Error",
                        showMessageBar: true
                    });
                }
            });
    };


    private GetVMsByScenarioAsync = async () => {
        const user = this.context;
        let vmLists = await scenarioManagementService.GetVMListByScenarioAsync(user.IdToken, this.props.scenario.id);

        if (vmLists === null) {
            this.setState({
                error: true,
                errorMessage: "No VM found or Error in fetching VM for scenario " + this.props.scenario.name,
                showMessageBar: true,
                vmDeployApiCall: true
            });
        } else if (vmLists.length < 1) {
            this.setState({
                error: true,
                errorMessage: "No VM found for scenario " + this.props.scenario.name,
                showMessageBar: true,
                vmDeployApiCall: true
            });
        } else {
            this.vmMetaData = vmLists;
        }
        this.setState({
            vmOptions: VMHelper.VMListToDropdownOptions(this.vmMetaData)
        });
    };

    private loggedInUserWithScenarioOwnerRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return Authorization.AuthorizeUser(user.roles, [AuthorizedRole.ScenarioOwner]);
    };

    private loggedInUserWithOrgAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator]);
    };

    private HandleSubmit = () => {
        this.setState({ isLoading: true });
        if (this.state.vmState === "Ready") {
            this.setState({ vmState: "Canary" }, () => this.PromoteVMAsync());
        }
        else if (this.state.vmState === "Canary") {
            this.setState({ vmState: "Production" }, () => this.PromoteVMAsync());
        }
    };

    private handleDropdownChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLDivElement | HTMLTextAreaElement
            >,
        value: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        this.newResource = { [target.id]: value };
        if (target.id === VMNameElementId) {
            this.setState(this.newResource);
            this.setState({ showVmDetails: true });
            this.setState({ readyToDeploy: false});
            this.populateVMInfoByScenarioId(value);
        }
    };

    private populateVMInfoByScenarioId = (scenarioId: string | undefined) => {
        this.vmMetaData.forEach( (element) => {
            if (element.id === scenarioId) {
                this.setState({ vmState: element.vmStatus });
                this.setState({ vmName: element.fileName });
                this.setState({ etag: element.etag });
                this.setState({ scenarioId: element.id });
                this.setState({ scenarioName: element.scenario });
                this.setState({ userVersion: element.userVersion });
                if (element.vmStatus === "Ready") {
                    this.setState({ promoteTo: "Ready to deploy to Canary" });
                    this.setState({ showConsentForProd: false });
                }
                else if (element.vmStatus === "Production") {
                    this.setState({ promoteTo: "VM is already deployed to Production" });
                    this.setState({ showConsentForProd: false });
                }
                else if (element.vmStatus === "Canary") {
                    this.setState({ promoteTo: "Ready to deploy to Production" });
                    this.setState({ showConsentForProd: true });
                }
                else {
                    this.setState({ promoteTo: "No VM for deployment" });
                    this.setState({ showConsentForProd: false });
                }
            }
        });
    };

    private CloseForm = () => {
        if (!this.state.error && this.state.apiResponse !== "" && !this.state.vmDeployApiCall) {
            this.props.onClose(true);
            this.setState({ showVmDetails: false });
            this.setState({ vmOptions: [] });
        }
        else {
            this.props.onClose(false);
        }
    };

    private EnableDeployButton = () => {
        if (this.state.readyToDeploy) {
            return true;
        } else if (this.state.vmState === "Ready") {
            return true;
        }
        return false;
    };

    handleCheckboxChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void => {
        this.setState({ readyToDeploy: !!checked });
    };

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying VM Deployment form');
        this.GetVMsByScenarioAsync();
    }

    UserFriendlyErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "PreconditionFailed":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.ScenarioManagement
                            .VMDEployFailedInternalServerErrorMessage
                    )
                );
            case "InternalServerError":
                {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.ScenarioManagement
                                .VMDeployFailedInvalidMessage,
                            this.Constant.getSupportEmail
                        )
                    );
                }
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.ScenarioManagement
                            .VMDeployFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    VMPromotionSuccessMessage = () => {
        if (this.state.errorMessage.includes("No VM found for scenario ")) {
            return "No VM Found for Scenario " + this.props.scenario.name;
        } else {
            return Common.ResourceManager.GetString(
                Common.Helper.stringFormat(
                    DeployVM_SuccessMessage,
                    this.state.vmName, this.state.scenarioName)
            );
        }
    };

    public render(): JSX.Element {
        const stackTokens: IStackTokens = { childrenGap: 15 };

        return (
            <div className="wrapper">
                <div className="securedevicesform">
                    <Label className="securedevicesform-title">{VMDeploymentFormTitle + " for " + this.props.scenario.name}</Label>
                        <div className="securedevicesform-body">
                            <div className="form__text">
                                {FormDescription}
                            </div>
                            
                            <div className="form__dropdown">
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor={VMNameElementId}
                            >
                                { "VM Name" }
                                </Label>
                                <Dropdown
                                    className="securedevices-dropdown crop-dropdown"
                                    id={VMNameElementId}
                                    onChange={(event, value) =>
                                        this.handleDropdownChange(event, value?.key as string)
                                    }
                                    placeholder={VMPlaceholder}
                                    options={this.state.vmOptions}
                                    calloutProps={{ doNotLayer: true }}
                                />
                            </div>

                            <div className="form__text" hidden={!this.state.showVmDetails}>
                                <Label className="securedevices-label">
                                    { "Current Status" }
                                </Label>
                                <div className="securedevices-input">
                                    { this.state.vmState !== null ? this.state.vmState : "Not Available" }
                                </div>
                            </div>

                            <div className="form__text" hidden={!this.state.showVmDetails}>
                                <Label className="securedevices-label">
                                    { "User Provided Version" }
                                </Label>
                                <div className="securedevices-input">
                                    {this.state.userVersion !== null ? this.state.userVersion : "Not Available" }
                                </div>
                            </div>

                            <div className="form__text" hidden={!this.state.showVmDetails}>
                                <Label className="securedevices-label">
                                    { "Promote To" }
                                </Label>
                                <div className="securedevices-input">
                                    {this.state.promoteTo !== null ? this.state.promoteTo : "Not Available" }
                                </div>
                            </div>
                            

                        <div className="securedevices-checkbox" hidden={!this.state.showConsentForProd}>
                            <Checkbox
                                id="checkbox"
                                styles={checkBoxStyles}
                                label="I acknowledge that VM is verfied in Canary devices *"
                                checked={this.state.readyToDeploy}
                                onChange={this.handleCheckboxChange}
                            />
                        </div>
                            
                        </div>
                        <div className="securedevicesform-footer"
                            style={{marginTop: "2rem"}}>
                            <Stack horizontal tokens={stackTokens}>
                                <PrimaryButton
                                    disabled={this.state.isLoading || this.state.apiResponse !== "" || !this.EnableDeployButton()}
                                    onClick={this.HandleSubmit}
                                    text="Deploy"
                                />
                                <DefaultButton
                                    disabled={this.state.isLoading || this.state.apiResponse !== ""}
                                    onClick={this.CloseForm}
                                    text="Cancel"
                                />
                                <img
                                    src={Loading}
                                    hidden={!this.state.isLoading}
                                    style={{
                                        width: "35px",
                                        height: "35px",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    alt="Loading"
                                ></img>
                            </Stack>
                        </div>
                    {this.state.showMessageBar && this.state.vmDeployApiCall ? (
                        <div className="securedevicesform-header">
                            {!this.state.error ? (
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    onDismiss={() => this.CloseForm()}
                                >
                                    {this.VMPromotionSuccessMessage()}
                                </MessageBar>
                            ) : (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    onDismiss={() => this.CloseForm()}
                                >
                                    {this.UserFriendlyErrorMessageMapping()}
                                </MessageBar>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                    </div>
                </div>
        );
    }
}